import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { configureStore } from './store/configureStore';
import emptyStore from './store/emptyStore';
import register from './registerServiceWorker';
import { sessionService } from 'redux-react-session';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './i18n';
import 'moment/locale/ar';

const store = configureStore(emptyStore);

sessionService.initSessionService(store, { driver: 'LOCALSTORAGE' });

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

register();
